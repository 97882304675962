<template>
  <div>
    <table-template
      :table_data="table_data"
      :isloading="isloading"
      :formatList="['isdelete']"
    >
      <template slot="isdelete" slot-scope="scope">
        {{
          scope.scope.row.isdelete == 2 || scope.scope.row.isdelete == -1
            ? "已处理"
            : "未处理"
        }}
      </template>
      <template slot-scope="scope">
        <el-button
          type="primary"
          size="small"
          @click="showDetail(scope.scope.row)"
          >详情</el-button
        >
        <el-popconfirm
          title="确认删除该条数据？"
          @confirm="delData(scope.scope.row.id)"
        >
          <el-button
            type="danger"
            slot="reference"
            size="small"
            style="margin-left: 10px"
            >删除</el-button
          >
        </el-popconfirm>
      </template>
    </table-template>
    <div style="text-align: right">
      <!-- <base-pageination :pageinfo="table_data.pageinfo" @handlePageClick="handlePageClick"></base-pageination> -->
    </div>
    <audit-detail ref="auditDetail"></audit-detail>
  </div>
</template>

<script>
import BasePageination from "../components/BasePageination.vue";
import ListConditionForm from "../components/ListConditionForm.vue";
import TableTemplate from "../components/TableTemplate.vue";
import { getBXList, delInfo } from "@/api/audit.js";
import AuditDetail from "../components/AuditDetail.vue";
export default {
  components: {
    ListConditionForm,
    TableTemplate,
    BasePageination,
    AuditDetail,
  },
  data() {
    return {
      table_data: {
        tableConfig: [
          { key: "username", name: "用户姓名" },
          { key: "phone", name: "联系方式" },
          { key: "address", name: "报修地址" },
          { key: "note", name: "报修描述" },
          // {key: 'cityNo', name: '燃气户号'},
          { key: "contractpath", name: "合同地址" },

          { key: "createtime", name: "提交时间" },
        ],
        data: [],
        pageinfo: { currentpage: 1, pageSize: 10, total: 0 },
      },
      isloading: false,
      bzDetail: null,
      detailList: [],
      dialogVisible: false,
    };
  },
  mounted() {
    this.handlePageClick(1);
  },
  methods: {
    fetchData() {
      this.isloading = true;
      getBXList({
        fromtype: "sys",
      }).then((res) => {
        this.isloading = false;
        const { code, results } = res;
        if (code === 10000) {
          this.table_data.data = results;
          // this.table_data.pageinfo = page
          // this.dialogVisible = true
        }
      });
    },
    handlePageClick(page) {
      this.table_data.pageinfo.currentpage = page;
      this.fetchData();
    },
    showDetail(obj) {
      this.$refs.auditDetail.setDialogShow(obj, "bx_id");
    },
    async delData(id) {
      const { code } = await delInfo({ id, type: 2 });
      if (code === 10000) this.fetchData();
      else this.$message.warning("操作失败！");
    },
  },
};
</script>

<style>
.el-dialog__body {
  padding: 10px;
}
</style>
